html {
  font-size: 16px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1400px) {

  html,
  body {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  height: 5px;
  ;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888856;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #88888856;
}

#root {
  height: 100%;
  background: white;
}

.page-overflow {
  height: 100%;
  max-height: 100%;
  overflow: scroll;
}

p {
  margin: 0;
}

button,
.btn {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.btn:hover,
button:hover {
  cursor: pointer;
}

.require-input {
  color: #F00;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn.btn-primary {
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  background: #0561AE;
  color: var(--text-text-1, #FFF);
  text-align: center;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn.btn-default {
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  background: #E3E9ED;
  color: #566A7F;
  text-align: center;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-2, #E3E9ED);
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.ant-modal .ant-modal-content {
  padding: 0;
}

.ant-modal .ant-modal-content .ant-modal-header {
  border-radius: 5px 5px 0px 0px;
  background: var(--primary-button, #FFE5E7);
  padding: 12px 0;
}

.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: var(--text-text-3, #1D2129);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.ant-modal .ant-modal-content .ant-modal-body {
  padding: 1rem;
  padding-bottom: 0.75rem;
  max-height: 90vh;
  overflow-y: auto;
}

.ant-modal .ant-modal-content .ant-modal-footer {
  padding: 1rem;
  margin-top: 0;
  display: flex;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn {
  height: 3.5rem;
  flex: 1;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn:disabled {
  opacity: 0.5;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary {
  background-color: #FF8890;
  border: 1px solid #F5767F;
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FF8890;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #FFFFFF;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary:hover {
  border: 1px solid #F5767F;
  background-color: #F5767F;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary:disabled {
  background-color: #FF8890;
  border: 1px solid #F5767F;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-default {
  color: #1D2129;
  background-color: #E5E6EB;
  border: 1px solid #86909C;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 5px;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-default:hover {
  border: 1px solid #86909C;
  background-color: #ADADAD;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-default:disabled {
  background-color: #E5E6EB;
  border: 1px solid #86909C;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.hidden {
  display: none;
}